import '../styles/index.css'
import Turbolinks from 'turbolinks'
import 'turbolinks-animate'

Turbolinks.start()

let prevBodyClasses = ''
let nextBodyClasses = ''

document.addEventListener('turbolinks:before-render', function (event) {
  const nextBody = event.data.newBody
  prevBodyClasses = document.querySelector('body').className
  nextBodyClasses = nextBody.className

  // Change the body classes for the next body, to the previous ones
  nextBody.setAttribute('class', prevBodyClasses)
})

// Animate between page transitions
document.addEventListener('turbolinks:load', function () {
  // Now change the body classes, to the actual new ones
  if (nextBodyClasses !== '' && nextBodyClasses !== prevBodyClasses) {
    setTimeout(function () {
      document.querySelector('body').setAttribute('class', nextBodyClasses)
    }, 10)
  }

  // .. and animate the body content
  TurbolinksAnimate.init({
    element: document.querySelector('body'),
    animation: 'fadeIn',
    duration: '0.8s',
    delay: 300,
    // reversedDisappearing: true,
  })
})

// Import all JavaScript & CSS files from src/_components
// import components from 'bridgetownComponents/**/*.{js,jsx,js.rb,css}'

// Hamburger menu
document.addEventListener('turbolinks:load', function () {
  const hamburgerButton = document.querySelector('#hamburger-button')
  const hamburgerMenu = document.querySelector('.hamburger-links')

  hamburgerButton.onclick = function () {
    hamburgerMenu.classList.toggle('hidden')
    // Hide .content-and-footer when menu is open
    document.querySelector('.content-and-footer').classList.toggle('invisible')
  }

  // The icon
  document.querySelector('#hamburger-icon').onclick = function () {
    this.classList.toggle('open')
  }
})
